import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Index = () => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle>User Agreement</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            The RACI Sizing Calculator is intended for use with simple bore
            calculations. For assistance with more complex projects, including
            those involving longer bores or larger diameter pipes, please
            contact Public Works Products. By using this software, the user
            agrees that Public Works Products shall not be held liable for any
            incidental, indirect, punitive, special, consequential, or similar
            damages ("Damages") arising out of or in connection with the sale,
            installation, or use of the software. Damages include, but are not
            limited to, costs related to rework, investigation, or any other
            related expenses. This limitation of liability is a condition for
            users to access and utilize the RACI Sizing Calculator. Please
            exercise caution and seek professional advice as needed when using
            this tool. For any additional information or assistance, feel free
            to contact Public Works Products.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            sx={{
              backgroundColor: "#007bff",
              color: "white",
              "&:hover": {
                backgroundColor: "#007bff",
                color: "white",
              },
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default Index;
