import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RACISizingCalculatorTabs from "./RACISizingCalculatorTabs";
import Disclamer from "./components/disclamer/Index";
function App() {
  return (
    <div>
      <ToastContainer />
      <Disclamer />
      <RACISizingCalculatorTabs />
    </div>
  );
}

export default App;
