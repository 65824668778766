import React from "react";

const CarrierAndCasingDropdowns = ({
  carrierType,
  setCarrierType,
  carrierSize,
  setCarrierSize,
  carrierThickness,
  setCarrierThickness,
  carrierData,
  contentSG,
  setContentSG,
  casingType,
  setCasingType,
  casingSize,
  setCasingSize,
  casingThickness,
  setCasingThickness,
  casingData,
  handleSelectChange,
  renderSizeOptions,
  renderThicknessOptions,
  showAdvancedOptions,
}) => (
  <div>
    <div>
      <div className="section" style={{ backgroundColor: "#DEECFE" }}>
        <h2>Carrier Information</h2>
      </div>
      <div>
        <label>Type of Pipe:</label>
        <select
          value={carrierType}
          onChange={handleSelectChange(setCarrierType)}
        >
          <option value="">Select Carrier Type</option>
          {Object.keys(carrierData).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Nominal Size:</label>
        <select
          value={carrierSize}
          onChange={handleSelectChange(setCarrierSize)}
        >
          <option value="">Select Size</option>
          {renderSizeOptions(carrierType)}
        </select>
      </div>

      {showAdvancedOptions && (
        <div>
          <label>Wall Thickness:</label>
          <select
            value={carrierThickness}
            onChange={handleSelectChange(setCarrierThickness)}
          >
            <option value="">Select Wall Thickness</option>
            {renderThicknessOptions(carrierType, carrierSize)}
          </select>
        </div>
      )}
      {showAdvancedOptions && (
        <div>
          <label>
            Content SG:
            <input
              type="number"
              value={contentSG}
              onChange={(e) => setContentSG(e.target.value)}
            />
          </label>
          <span>(water SG = 1)</span>
        </div>
      )}
      <div>
        <div className="section" style={{ backgroundColor: "#DEECFE" }}>
          <h2>Casing Information</h2>
        </div>
        <div>
          <label>Type of Pipe:</label>
          <select
            value={casingType}
            onChange={handleSelectChange(setCasingType)}
          >
            <option value="">Select Casing Type</option>
            {Object.keys(casingData).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Nominal Size:</label>
          <select
            value={casingSize}
            onChange={handleSelectChange(setCasingSize)}
          >
            <option value="">Select Size</option>
            {renderSizeOptions(casingType)}
          </select>
        </div>

        {
          <div>
            <label>Wall Thickness:</label>
            <select
              value={casingThickness}
              onChange={handleSelectChange(setCasingThickness)}
            >
              <option value="">Select Wall Thickness</option>
              {renderThicknessOptions(casingType, casingSize)}
            </select>
          </div>
        }
      </div>
    </div>
  </div>
);

export default CarrierAndCasingDropdowns;
