/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import pipeData from "./PipeData.json";
import products from "./products.json";
import "./App.css";
import CarrierAndCasingDropdowns from "./CarrierAndCasingDropdowns";
import DrawingApp from './DrawingApp';
import elementRunnerDetails from './elementRunners.json';

<style>
  {`
  .form-group {
    margin-bottom: 1rem;
  }

  .form-label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .input-group {
    display: flex;
    align-items: center;
  }

  .input-group .form-control {
    flex-grow: 1;
  }

  .input-group span {
    margin-left: 0.5rem;
  }

  .section {
    margin-bottom: 2rem;
  }

  .section-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  .section-title h2 {
    margin: 0;
  }

  .section-title .arrow {
    transition: transform 0.3s;
  }

  .section-title .arrow-down {
    transform: rotate(90deg);
  }
`}
</style>;

const arrowStyle = `
  .arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 5px;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
    width: 10px;
    height: 10px;
  }

  .arrow-right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .arrow-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .title:hover .arrow {
    opacity: 1;
  }

  .title:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`;

const carrierData = {
  "PVC Water (C900)": {
    4: ["DR-18", "DR-14", "DR-21", "DR-25"],
    6: ["DR-18", "DR-14", "DR-21", "DR-25"],
    8: ["DR-18", "DR-14", "DR-21", "DR-25"],
    10: ["DR-18", "DR-14", "DR-21", "DR-25"],
    12: ["DR-18", "DR-14", "DR-21", "DR-25"],
    14: ["DR-18", "DR-14", "DR-21", "DR-25"],
    16: ["DR-18", "DR-14", "DR-21", "DR-25"],
    18: ["DR-18", "DR-14", "DR-21", "DR-25"],
    20: ["DR-18", "DR-14", "DR-21", "DR-25"],
    24: ["DR-18", "DR-14", "DR-21", "DR-25"],
    30: ["DR-18", "DR-14", "DR-21", "DR-25"],
    36: ["DR-18", "DR-14", "DR-21", "DR-25"],
    42: ["DR-18", "DR-14", "DR-21", "DR-25"],
    48: ["DR-18", "DR-14", "DR-21", "DR-25"],
    54: ["DR-18", "DR-14", "DR-21", "DR-25"],
    60: ["DR-18", "DR-14", "DR-21", "DR-25"],
  },
  "PVC Sewer (D3034/F679)": {
    4: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    6: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    8: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    10: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    12: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    15: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    18: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    21: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    24: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    27: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    30: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    36: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    42: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    48: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    54: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    60: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
  },
  "PVC Pressure (IPS)": {
    1.5: ["SDR-26", "SDR-21", "SDR-32.5"],
    2: ["SDR-26", "SDR-21", "SDR-32.5", "SDR-17"],
    2.5: ["SDR-26", "SDR-21", "SDR-32.5", "SDR-17"],
    3: ["SDR-26", "SDR-21", "SDR-41", "SDR-32.5", "SDR-17"],
    4: ["SDR-26", "SDR-21", "SDR-41", "SDR-32.5", "SDR-17"],
    5: ["SDR-26", "SDR-32.5", "SDR-41"],
    6: ["SDR-26", "SDR-21", "SDR-64", "SDR-41", "SDR-32.5", "SDR-17"],
    8: ["SDR-26", "SDR-21", "SDR-64", "SDR-41", "SDR-32.5", "SDR-17"],
    10: ["SDR-26", "SDR-21", "SDR-64", "SDR-41", "SDR-32.5", "SDR-17"],
    12: ["SDR-26", "SDR-21", "SDR-64", "SDR-41", "SDR-32.5", "SDR-17"],
  },
  "FPVC Water (C900) Fused": {
    4: ["DR-18"],
    6: ["DR-18"],
    8: ["DR-18"],
    10: ["DR-18"],
    12: ["DR-18"],
    14: ["DR-18"],
    16: ["DR-18"],
    18: ["DR-18"],
    20: ["DR-18"],
    24: ["DR-18"],
    30: ["DR-18"],
    36: ["DR-18"],
    42: ["DR-18"],
    48: ["DR-18"],
    54: ["DR-18"],
    60: ["DR-18"],
  },
  "Ductile Iron Pipe": {
    4: ["Class 350"],
    6: ["Class 350"],
    8: ["Class 350"],
    10: ["Class 350"],
    12: ["Class 350"],
    14: ["Class 250", "Class 300", "Class 350"],
    16: ["Class 250", "Class 300", "Class 350"],
    18: ["Class 250", "Class 300", "Class 350"],
    20: ["Class 250", "Class 300", "Class 350"],
    24: ["Class 200", "Class 250", "Class 300", "Class 350"],
    30: ["Class 150", "Class 200", "Class 250", "Class 300", "Class 350"],
    36: ["Class 150", "Class 200", "Class 250", "Class 300", "Class 350"],
    42: ["Class 150", "Class 200", "Class 250", "Class 300", "Class 350"],
    48: ["Class 150", "Class 200", "Class 250", "Class 300", "Class 350"],
    54: ["Class 150", "Class 200", "Class 250", "Class 300", "Class 350"],
    60: ["Class 150", "Class 200", "Class 250", "Class 300", "Class 350"],
    64: ["Class 150", "Class 200", "Class 250", "Class 300", "Class 350"],
  },
  "HDPE (IPS)": {
    2: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    3: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    4: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    5: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    6: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    8: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    10: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    12: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    14: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    16: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    18: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    20: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    22: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    24: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    26: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    28: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    30: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    32: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    34: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    36: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    38: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    40: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    42: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    44: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    46: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    48: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    50: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    54: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    63: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
  },
  "HDPE (DIPS)": {
    4: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    6: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    8: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    10: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    12: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    14: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    16: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    18: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    20: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    24: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    30: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    36: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    42: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    48: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
  },
  "Steel (NPS)": {
    1.5: ["0.145'' SCH 40 (STD)", "0.20'' SCH 80 (XS)"],
    2: ["0.154'' SCH 40 (STD)", "0.218'' SCH 80 (XS)"],
    2.5: ["0.203'' SCH 40 (STD)", "0.276'' SCH 80 (XS)"],
    3: ["0.216'' SCH 40 (STD)", "0.300'' SCH 80 (XS)"],
    3.5: ["0.225'' SCH 40 (STD)", "0.318'' SCH 80 (XS)"],
    4: ["0.237'' SCH 40 (STD)", "0.337'' SCH 80 (XS)"],
    5: ["0.258'' SCH 40 (STD)", "0.375'' SCH 80 (XS)", "0.258'' Other"],
    6: ["0.280'' SCH 40 (STD)", "0.432'' SCH 80 (XS)", "0.25'' Other"],
    8: ["0.322'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    10: ["0.365'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    12: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    14: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    16: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    18: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    20: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    22: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    24: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    26: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    28: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    30: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    32: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    34: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    36: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    38: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    40: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    42: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    44: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    46: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    48: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    50: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    52: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    54: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    56: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    58: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    60: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "1.0'' Other"],
    62: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "1.0'' Other"],
    64: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "1.0'' Other"],
    66: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "1.0'' Other"],
    72: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "1.0'' Other"],
    84: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "1.0'' Other"],
  },
};
const casingData = {
  "PVC Water (C900)": {
    4: ["DR-18", "DR-14", "DR-21", "DR-25"],
    6: ["DR-18", "DR-14", "DR-21", "DR-25"],
    8: ["DR-18", "DR-14", "DR-21", "DR-25"],
    10: ["DR-18", "DR-14", "DR-21", "DR-25"],
    12: ["DR-18", "DR-14", "DR-21", "DR-25"],
    14: ["DR-18", "DR-14", "DR-21", "DR-25"],
    16: ["DR-18", "DR-14", "DR-21", "DR-25"],
    18: ["DR-18", "DR-14", "DR-21", "DR-25"],
    20: ["DR-18", "DR-14", "DR-21", "DR-25"],
    24: ["DR-18", "DR-14", "DR-21", "DR-25"],
    30: ["DR-18", "DR-14", "DR-21", "DR-25"],
    36: ["DR-18", "DR-14", "DR-21", "DR-25"],
    42: ["DR-18", "DR-14", "DR-21", "DR-25"],
    48: ["DR-18", "DR-14", "DR-21", "DR-25"],
    54: ["DR-18", "DR-14", "DR-21", "DR-25"],
    60: ["DR-18", "DR-14", "DR-21", "DR-25"],
  },
  "PVC Sewer (D3034/F679)": {
    4: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    6: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    8: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    10: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    12: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    15: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    18: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    21: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    24: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    27: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    30: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    36: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    42: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    48: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    54: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
    60: ["SDR-26 (HW) / PS115", "SDR-35 / PS46"],
  },
  "PVC Pressure (IPS)": {
    1.5: ["SDR-26", "SDR-21", "SDR-32.5"],
    2: ["SDR-26", "SDR-21", "SDR-32.5", "SDR-17"],
    2.5: ["SDR-26", "SDR-21", "SDR-32.5", "SDR-17"],
    3: ["SDR-26", "SDR-21", "SDR-41", "SDR-32.5", "SDR-17"],
    4: ["SDR-26", "SDR-21", "SDR-41", "SDR-32.5", "SDR-17"],
    5: ["SDR-26", "SDR-32.5", "SDR-41"],
    6: ["SDR-26", "SDR-21", "SDR-64", "SDR-41", "SDR-32.5", "SDR-17"],
    8: ["SDR-26", "SDR-21", "SDR-64", "SDR-41", "SDR-32.5", "SDR-17"],
    10: ["SDR-26", "SDR-21", "SDR-64", "SDR-41", "SDR-32.5", "SDR-17"],
    12: ["SDR-26", "SDR-21", "SDR-64", "SDR-41", "SDR-32.5", "SDR-17"],
  },
  "Ductile Iron Pipe": {
    4: ["Class 350"],
    6: ["Class 350"],
    8: ["Class 350"],
    10: ["Class 350"],
    12: ["Class 350"],
    14: ["Class 250", "Class 300", "Class 350"],
    16: ["Class 250", "Class 300", "Class 350"],
    18: ["Class 250", "Class 300", "Class 350"],
    20: ["Class 250", "Class 300", "Class 350"],
    24: ["Class 200", "Class 250", "Class 300", "Class 350"],
    30: ["Class 150", "Class 200", "Class 250", "Class 300", "Class 350"],
    36: ["Class 150", "Class 200", "Class 250", "Class 300", "Class 350"],
    42: ["Class 150", "Class 200", "Class 250", "Class 300", "Class 350"],
    48: ["Class 150", "Class 200", "Class 250", "Class 300", "Class 350"],
    54: ["Class 150", "Class 200", "Class 250", "Class 300", "Class 350"],
    60: ["Class 150", "Class 200", "Class 250", "Class 300", "Class 350"],
    64: ["Class 150", "Class 200", "Class 250", "Class 300", "Class 350"],
  },
  "HDPE (IPS)": {
    2: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    3: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    4: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    5: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    6: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    8: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    10: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    12: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    14: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    16: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    18: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    20: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    22: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    24: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    26: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    28: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    30: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    32: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    34: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    36: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    38: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    40: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    42: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    44: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    46: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    48: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    50: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    54: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
    63: ["DR-7.3", "DR-9", "DR-11", "DR-17", "DR-21", "DR-26", "DR-32.5"],
  },
  "HDPE (DIPS)": {
    4: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    6: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    8: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    10: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    12: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    14: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    16: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    18: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    20: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    24: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    30: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    36: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    42: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
    48: ["DR-7", "DR-9", "DR-11", "DR-13.5", "DR-17", "DR-21"],
  },
  "Steel (NPS)": {
    1.5: ["0.145'' SCH 40 (STD)", "0.20'' SCH 80 (XS)"],
    2: ["0.154'' SCH 40 (STD)", "0.218'' SCH 80 (XS)"],
    2.5: ["0.203'' SCH 40 (STD)", "0.276'' SCH 80 (XS)"],
    3: ["0.216'' SCH 40 (STD)", "0.300'' SCH 80 (XS)"],
    3.5: ["0.225'' SCH 40 (STD)", "0.318'' SCH 80 (XS)"],
    4: ["0.237'' SCH 40 (STD)", "0.337'' SCH 80 (XS)"],
    5: ["0.258'' SCH 40 (STD)", "0.375'' SCH 80 (XS)", "0.258'' Other"],
    6: ["0.280'' SCH 40 (STD)", "0.432'' SCH 80 (XS)", "0.25'' Other"],
    8: ["0.322'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    10: ["0.365'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    12: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    14: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    16: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    18: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    20: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    22: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    24: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    26: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.25'' Other"],
    28: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    30: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    32: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    34: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    36: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    38: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    40: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    42: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    44: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    46: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    48: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    50: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    52: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    54: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    56: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    58: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "0.625'' Other"],
    60: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "1.0'' Other"],
    62: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "1.0'' Other"],
    64: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "1.0'' Other"],
    66: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "1.0'' Other"],
    72: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "1.0'' Other"],
    84: ["0.375'' SCH 40 (STD)", "0.50'' SCH 80 (XS)", "1.0'' Other"],
  },
};

const RACISizingCalculator = ({
  lengthOfPipe,
  setLengthOfPipe,
  numBores,
  setNumBores,
  elementsConfig, // Use the prop instead of the local state
  setElementsConfig, // Use the prop instead of the local state
  carrierPipe, // Add this prop
  setCarrierPipe, // Add this prop
  casingPipe,
  setCasingPipe,
  setRingConfiguration,
  displayOnlySummary,
}) => {
  const [carrierType, setCarrierType] = useState("");
  const [carrierSize, setCarrierSize] = useState("");
  const [carrierThickness, setCarrierThickness] = useState("");
  const [casingType, setCasingType] = useState("");
  const [casingSize, setCasingSize] = useState("");
  const [casingThickness, setCasingThickness] = useState("");
  const [minimumGap, setMinimumGap] = useState(0.01);
  const [bellClearance, setBellClearance] = useState(0.01);
  const [spacing, setSpacing] = useState(6);
  const [contentSG, setContentSG] = useState(1);
  const [weightOfCarrierPipeFull, setWeightOfCarrierPipeFull] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [raciRingOD, setRaciRingOD] = useState(0);
  const [totalGap, setTotalGap] = useState(0);
  const [calculatedBellClearance, setCalculatedBellClearance] =
    useState("-n/a-");
  const [customBellOD, setCustomBellOD] = useState(null);
  const [customCarrierOD, setCustomCarrierOD] = useState(null);
  const [customCasingOD, setCustomCasingOD] = useState(null);
  const [customCasingWT, setCustomCasingWT] = useState(null);
  const [customWeight, setCustomWeight] = useState(null);
  const [showSpacerSizingCriteria, setShowSpacerSizingCriteria] =
    useState(true);
  const [showSelectedInformation, setShowSelectedInformation] = useState(true);
  const [showSpacerSelectionCriteria, setShowSpacerSelectionCriteria] =
    useState(true);
  const [bellCheckboxChecked, setBellCheckboxChecked] = useState(true);
  const [casingID, setCasingID] = useState(null);
  const [bellODErrorMessage, setBellODErrorMessage] = useState(null);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [combineLengths, setCombineLengths] = useState(false);


  const handleSelectChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const findElementsConfig = (product, carrierOD) => {
    if (!product || carrierOD === "") return null;

    const odRange = product.carrierODRanges.find(
      (range) => carrierOD >= range.min && carrierOD <= range.max
    );

    return odRange ? odRange.elements : null;
  };

  const renderPipeTypes = () => {
    return (
      <div style={{ color: "blue", fontWeight: 700 }}>
        <p
          style={{
            color: "#2A7DE1",
          }}
        >
          Ring Configuration:{" "}
          <span
            style={{
              color: "black",
            }}
          >
            {elementsConfig
              ? Object.entries(elementsConfig).map(
                  ([element, quantity], index, arr) => {
                    const elementText =
                      quantity === 1
                        ? `${quantity} ${element} Element`
                        : `${quantity} ${element} Elements`;
                    const separatorText = index < arr.length - 1 ? " and " : "";
                    return elementText + separatorText;
                  }
                )
              : null}{" "}
            to form One Spacer.
          </span>
        </p>
      </div>
    );
  };

  const renderSizeOptions = (type) => {
    if (!type || !carrierData[type]) return null;
    return Object.keys(carrierData[type]).map((size) => (
      <option key={size} value={size}>
        {size}
      </option>
    ));
  };
  const calculateCasingID = (casingOD, casingWT) => {
    if (casingOD === null || casingWT === null) {
      return null;
    }
    return (casingOD - casingWT * 2).toFixed(2);
  };
  const renderThicknessOptions = (type, size) => {
    if (!type || !size || !carrierData[type] || !carrierData[type][size])
      return null;
    return carrierData[type][size].map((thickness) => (
      <option key={thickness} value={thickness}>
        {thickness}
      </option>
    ));
  };

  const getPipeInformation = (type, size, thickness) => {
    if (!type || !size || !thickness) return null;

    const pipeName = `${type} ${size} ${thickness}`;
    const pipeInfo = pipeData.find((pipe) => pipe["Pipe Name"] === pipeName);
    return pipeInfo;
  };

  const selectedCarrierInfo = getPipeInformation(
    carrierType,
    carrierSize,
    carrierThickness
  );
  const selectedCasingInfo = getPipeInformation(
    casingType,
    casingSize,
    casingThickness
  );

  const maxRunnerHeight = useMemo(() => {
    if (!selectedCasingInfo || !minimumGap || !selectedCarrierInfo) return "";

    const carrierOD =
      customCarrierOD !== null
        ? parseFloat(customCarrierOD)
        : parseFloat(selectedCarrierInfo["Pipe OD"]);

    const casingID = parseFloat(
      calculateCasingID(
        customCasingOD !== null
          ? customCasingOD
          : selectedCasingInfo && selectedCasingInfo["Pipe OD"],
        customCasingWT !== null
          ? customCasingWT
          : selectedCasingInfo && selectedCasingInfo["Pipe Wall Thickness"]
      )
    );

    return (casingID - parseFloat(minimumGap) - carrierOD) / 2;
  }, [
    selectedCasingInfo,
    minimumGap,
    selectedCarrierInfo,
    customCarrierOD,
    customCasingOD,
    customCasingWT,
  ]);

  const minRunnerHeight = useMemo(() => {
    if (!selectedCarrierInfo) return "-n/a-";

    const bellOD =
      customBellOD !== null && customBellOD !== ""
        ? parseFloat(customBellOD)
        : selectedCarrierInfo["Bell OD"]
        ? parseFloat(selectedCarrierInfo["Bell OD"])
        : null;

    const carrierOD =
      customCarrierOD !== null
        ? parseFloat(customCarrierOD)
        : parseFloat(selectedCarrierInfo["Pipe OD"]);

    if (isNaN(carrierOD) || !bellClearance) {
      return 0.59;
    }

    if (
      bellOD === null ||
      isNaN(bellOD) ||
      bellOD === "" ||
      !bellCheckboxChecked
    ) {
      return 0.59;
    }

    return (bellOD - carrierOD + parseFloat(bellClearance) * 2) / 2;
  }, [
    selectedCarrierInfo,
    bellClearance,
    customBellOD,
    customCarrierOD,
    bellCheckboxChecked,
  ]);

  const calculateWeightOfCarrierPipeFull = useCallback(
    (customWeight = null) => {
      if (selectedCarrierInfo && contentSG) {
        const pipeWeight = parseFloat(selectedCarrierInfo["Pipe Weight"]);
        const contentWeight =
          contentSG *
          0.036127 *
          12 *
          (Math.PI / 4) *
          parseFloat(selectedCarrierInfo["Pipe ID"]) ** 2;
        const totalWeight =
          customWeight !== null
            ? parseFloat(customWeight)
            : pipeWeight + contentWeight;
        setWeightOfCarrierPipeFull(totalWeight);
      }
    },
    [contentSG, selectedCarrierInfo]
  );

  useEffect(() => {
    calculateWeightOfCarrierPipeFull();
  }, [selectedCarrierInfo, contentSG]);

  useEffect(() => {
    if (carrierType && carrierSize) {
      setCarrierPipe(`${carrierSize}" ${carrierThickness} ${carrierType}`);
    }
  }, [carrierType, carrierSize, carrierThickness]);

  useEffect(() => {
    if (casingType && casingSize) {
      setCasingPipe(`${casingSize}" ${casingThickness} ${casingType}`);
    }
  }, [casingType, casingSize, casingThickness]);

  useEffect(() => {
    if (setNumBores) {
      setNumBores(numBores);
    }
  }, [numBores]);

  useEffect(() => {
    if (elementsConfig && setRingConfiguration) {
      const ringConfigurationString = Object.entries(elementsConfig)
        .map(([element, quantity]) => `${quantity} ${element} Elements`)
        .join(" and ");

      setRingConfiguration(
        `Spacer Configuration: ${ringConfigurationString} to form one Spacer`
      );
    }
  }, [elementsConfig]);

  const getSelectedProductInfo = useCallback(() => {
    if (!selectedProduct) return null;
    const product = products.find(
      (product) => product.id === parseInt(selectedProduct, 10)
    );
    return product ? product : null;
  }, [selectedProduct]);

  useEffect(() => {
    const selectedProductInfo = getSelectedProductInfo();

    if (selectedCarrierInfo && selectedCasingInfo && selectedProductInfo) {
      const carrierOD =
        customCarrierOD !== null
          ? customCarrierOD
          : selectedCarrierInfo && selectedCarrierInfo["Pipe OD"];
      const bellOD =
        customBellOD !== null && customBellOD !== ""
          ? parseFloat(customBellOD)
          : parseFloat(selectedCarrierInfo["Bell OD"]);

      let newRaciRingOD = NaN; // set as NaN to indicate "not a number"
      if (selectedProductInfo.fixedRunnerHeight) {
        newRaciRingOD =
          parseFloat(carrierOD) +
          parseFloat(selectedProductInfo.fixedRunnerHeight) * 2;
      }
      setRaciRingOD(isNaN(newRaciRingOD) ? "-" : newRaciRingOD);

      let newTotalGap = "-";
      if (casingID && newRaciRingOD !== "-" && casingID !== 0) {
        newTotalGap = parseFloat(casingID) - parseFloat(newRaciRingOD);
      }
      setTotalGap(newTotalGap);

      const newCalculatedBellClearance =
        bellOD !== null && !isNaN(bellOD) && newRaciRingOD !== "-"
          ? (parseFloat(newRaciRingOD) - bellOD) / 2
          : "-n/a-";
      setCalculatedBellClearance(newCalculatedBellClearance);
    } else {
      setRaciRingOD("-");
      setTotalGap("-");
      setCalculatedBellClearance("-n/a-");
    }
  }, [
    selectedCarrierInfo,
    selectedCasingInfo,
    selectedProduct,
    customCarrierOD,
    customBellOD,
    casingID,
    getSelectedProductInfo,
  ]);

  const selectedProductInfo = getSelectedProductInfo();
  useEffect(() => {
    const selectedProductInfo = getSelectedProductInfo();
    const carrierOD =
      customCarrierOD !== null
        ? customCarrierOD
        : selectedCarrierInfo
        ? parseFloat(selectedCarrierInfo["Pipe OD"])
        : "";

    const newElementsConfig = findElementsConfig(
      selectedProductInfo,
      carrierOD
    );

    if (setElementsConfig) {
      setElementsConfig(newElementsConfig);
    }
  }, [
    selectedCarrierInfo,
    selectedProduct,
    customCarrierOD,
    getSelectedProductInfo,
  ]); // Add customCarrierOD to the dependency array

  const filterProducts = useCallback(() => {
    if (!selectedCarrierInfo || !selectedCasingInfo) {
      return [];
    }

    const carrierOD =
      customCarrierOD !== null
        ? parseFloat(customCarrierOD)
        : parseFloat(selectedCarrierInfo["Pipe OD"]);

    const filtered = products.filter((product) => {
      const carrierODInRange = product.carrierODRanges.some(
        (range) => carrierOD >= range.min && carrierOD <= range.max
      );

      const weightWithSpacing = weightOfCarrierPipeFull * spacing;
      const weightInRange = weightWithSpacing <= product.maxWeight;

      const runnerHeightInRange =
        minRunnerHeight <= product.fixedRunnerHeight &&
        maxRunnerHeight >= product.fixedRunnerHeight;

      const isProductValid =
        carrierODInRange && weightInRange && runnerHeightInRange;

      return isProductValid;
    });

    return filtered.sort((a, b) => {
      const totalGapA =
        parseFloat(selectedCasingInfo["Pipe ID"]) -
        (parseFloat(selectedCarrierInfo["Pipe OD"]) +
          parseFloat(a.fixedRunnerHeight) * 2);
      const totalGapB =
        parseFloat(selectedCasingInfo["Pipe ID"]) -
        (parseFloat(selectedCarrierInfo["Pipe OD"]) +
          parseFloat(b.fixedRunnerHeight) * 2);

      const bellClearanceA =
        (parseFloat(selectedCarrierInfo["Pipe OD"]) +
          parseFloat(a.fixedRunnerHeight) * 2 -
          parseFloat(selectedCarrierInfo["Bell OD"])) /
        2;
      const bellClearanceB =
        (parseFloat(selectedCarrierInfo["Pipe OD"]) +
          parseFloat(b.fixedRunnerHeight) * 2 -
          parseFloat(selectedCarrierInfo["Bell OD"])) /
        2;

      // Assign a high value to push products with gap or bell clearance less than 0.5 to the end of the list
      const sortedTotalGapA =
        totalGapA < 0.5 || bellClearanceA < 0.5 ? 1000 : totalGapA;
      const sortedTotalGapB =
        totalGapB < 0.5 || bellClearanceB < 0.5 ? 1000 : totalGapB;

      // For products with the same sorted total gap, prioritize the one that has greater gap than bell clearance
      if (sortedTotalGapA === sortedTotalGapB) {
        if (totalGapA > bellClearanceA && totalGapB <= bellClearanceB) {
          return -1;
        }
        if (totalGapB > bellClearanceB && totalGapA <= bellClearanceA) {
          return 1;
        }
        const priorityA = priorityGroup(a.id, carrierOD);
    const priorityB = priorityGroup(b.id, carrierOD);
    return priorityA - priorityB;
      }

      return sortedTotalGapA - sortedTotalGapB;
    });
  }, [
    customCarrierOD,
    maxRunnerHeight,
    minRunnerHeight,
    selectedCarrierInfo,
    selectedCasingInfo,
    spacing,
    weightOfCarrierPipeFull,
  ]);


  
  const calculateRunnersAndWidth = (elementsConfig) => {
    // Check if elementsConfig is undefined or null
    if (!elementsConfig) {
      // Return default values if elementsConfig is not valid
      return { totalRunners: 0, averageWidth: 0 };
    }
  
    let totalRunners = 0;
    let totalWidth = 0;
  
    // Proceed with your existing logic
    Object.entries(elementsConfig).forEach(([element, quantity]) => {
      const elementDetail = elementRunnerDetails.find(el => el.element === element);
      if (elementDetail) {
        totalRunners += elementDetail.numRunners * quantity;
        totalWidth += elementDetail.runnerWidth * quantity;
      }
    });
  
    // Calculate average width and return the results
    return { totalRunners, averageWidth: totalRunners > 0 ? totalWidth / totalRunners : 0 };
  };

  const priorityGroup = (id, carrierOD) => {
    if (carrierOD < 4) {
      if (id >= 1 && id <= 5) return 1;
      else return 2;
    } else if (carrierOD >= 4 && carrierOD < 12) {
      if (id >= 6 && id <= 9) return 1;
      else if (id >= 10 && id <= 18) return 2;
      else if (id >= 1 && id <= 5) return 3;
      else return 4;
    } else if (carrierOD >= 12 && carrierOD < 20) {
      if (id >= 10 && id <= 18) return 1;
      else if (id >= 6 && id <= 9) return 2;
      else if (id > 18) return 3;
      else return 4;
    } else {
      if (id > 18) return 1;
      else if (id >= 12 && id <= 20) return 2;
      else if (id >= 6 && id <= 9) return 3;
      else return 4;
    }
  };

  
  
  useEffect(() => {
    const newFilteredProducts = filterProducts();
    setFilteredProducts(newFilteredProducts);

    // Update the selectedProduct to the first available option
    if (newFilteredProducts.length > 0) {
      setSelectedProduct(newFilteredProducts[0].id);
    } else {
      setSelectedProduct(null);
    }
  }, [
    selectedCarrierInfo,
    selectedCasingInfo,
    weightOfCarrierPipeFull,
    maxRunnerHeight,
    minRunnerHeight,
    spacing,
    customCarrierOD,
  ]);

  useEffect(() => {
    setCustomBellOD(null);
    setHasBell(
      selectedCarrierInfo && typeof selectedCarrierInfo["Bell OD"] === "number"
    );
  }, [selectedCarrierInfo]);

  useEffect(() => {
    setCustomCarrierOD(null);
    setHasCarrier(
      selectedCarrierInfo && typeof selectedCarrierInfo["Pipe OD"] === "number"
    );
  }, [selectedCarrierInfo]);

  useEffect(() => {
    setCustomCasingOD(null);
    setHasCasing(
      selectedCasingInfo && selectedCasingInfo["Pipe OD"] === "number"
    );
  }, [selectedCasingInfo]);

  useEffect(() => {
    setCustomCasingWT(null);
    setHasCasingWT(
      selectedCasingInfo &&
        selectedCasingInfo["Pipe Wall Thickness"] === "number"
    );
  }, [selectedCasingInfo]);

  useEffect(() => {
    setFilteredProducts(filterProducts());
  }, [
    selectedCarrierInfo,
    selectedCasingInfo,
    weightOfCarrierPipeFull,
    maxRunnerHeight,
    minRunnerHeight,
    spacing,
    customCarrierOD,
    customBellOD,
  ]);

  useEffect(() => {
    if (!selectedCarrierInfo) {
      setBellODErrorMessage(null);
      return;
    }

    const carrierOD =
      customCarrierOD !== null && customCarrierOD !== ""
        ? parseFloat(customCarrierOD)
        : parseFloat(selectedCarrierInfo["Pipe OD"]);

    const bellOD =
      customBellOD !== null && customBellOD !== ""
        ? parseFloat(customBellOD)
        : parseFloat(selectedCarrierInfo["Bell OD"]);

    if (carrierOD > bellOD) {
      setBellODErrorMessage("Bell OD cannot be less than the carrier OD.");
    } else {
      setBellODErrorMessage(null);
    }
  }, [selectedCarrierInfo, customCarrierOD, customBellOD]);

  const handleProductChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  const handleBellODChange = (e) => {
    setCustomBellOD(e.target.value);
  };

  const handleCarrierODChange = (e) => {
    setCustomCarrierOD(e.target.value);
  };

  const handleCasingODChange = (e) => {
    setCustomCasingOD(e.target.value);
  };

  const handleCasingWTChange = (e) => {
    setCustomCasingWT(e.target.value);
  };

  const handleweightOfCarrierPipeFullChange = (e) => {
    const customWeight = e.target.value;
    setCustomWeight(customWeight);
    calculateWeightOfCarrierPipeFull(customWeight);
  };

  function getBackgroundColor(value) {
    if (value < 0.6) {
      return "red";
    } else if (value >= 0.6 && value <= 1) {
      return "yellow";
    }
    return "transparent";
  }

  const getErrorMessage = () => {
    if (!selectedProduct) {
      if (selectedCarrierInfo && selectedCasingInfo) {
        const bellClearanceTooSmall =
          calculatedBellClearance < bellClearance && totalGap > minimumGap;
        const totalGapTooSmall =
          calculatedBellClearance > bellClearance && totalGap < minimumGap;
        const bellODTooLarge =
          selectedCarrierInfo["Bell OD"] &&
          selectedCarrierInfo["Bell OD"] > selectedCasingInfo["Pipe ID"];

        if (bellClearanceTooSmall || totalGapTooSmall || bellODTooLarge) {
          return "The outer diameter of the carrier pipe, or the bell, is too large to fit inside your chosen casing. To fix this, you can either select a larger casing that can comfortably accommodate the carrier pipe or choose a smaller carrier pipe that can fit into the existing casing. If you're unsure or need further assistance, don't hesitate to contact a representative from Public Works Products.";
        }
      }
    }
    return null;
  };

  const [hasBell, setHasBell] = useState(
    selectedCarrierInfo && typeof selectedCarrierInfo["Bell OD"] === "number"
  );

  const [hasCarrier, setHasCarrier] = useState(
    selectedCarrierInfo && typeof selectedCarrierInfo["Pipe OD"] === "number"
  );

  const [hasCasing, setHasCasing] = useState(
    selectedCasingInfo && typeof selectedCasingInfo["Pipe OD"] === "number"
  );

  const [hasCasingWT, setHasCasingWT] = useState(
    selectedCasingInfo && selectedCasingInfo["Pipe Wall Thickness"] === "number"
  );

  const handleBellCheckboxChange = (e) => {
    setHasBell(e.target.checked);
    setBellCheckboxChecked(e.target.checked);
    if (!e.target.checked) {
      setCustomBellOD(null);
    }
  };

  const BellODErrorMessage = () => {
    if (bellODErrorMessage) {
      return <p style={{ color: "red" }}>{bellODErrorMessage}</p>;
    }
    return null;
  };

  useEffect(() => {
    setHasBell(
      selectedCarrierInfo && typeof selectedCarrierInfo["Bell OD"] === "number"
    );
  }, [selectedCarrierInfo]);

  useEffect(() => {
    const casingOD =
      customCasingOD !== null
        ? parseFloat(customCasingOD)
        : selectedCasingInfo && parseFloat(selectedCasingInfo["Pipe OD"]);

    const casingWT =
      customCasingWT !== null
        ? parseFloat(customCasingWT)
        : selectedCasingInfo &&
          parseFloat(selectedCasingInfo["Pipe Wall Thickness"]);

    const newCasingID = calculateCasingID(casingOD, casingWT);
    setCasingID(newCasingID);
  }, [customCasingOD, customCasingWT, selectedCasingInfo]);

  const calculateLeadingSpacers = (length) => {
    if (length <= 30) return 1;
    if (length > 30 && length <= 200) return 2;
    if (length > 200 && length <= 400) return 3;
    return 3;
  };

  const calculateTrailingSpacers = (length) => {
    if (length <= 30) return 1;
    return 2;
  };

  const calculateTotalSpacers = (length, leading, trailing) => {
    let totalSpacers;
    if (length < 12) {
      totalSpacers = 2;
    } else if (length >= 12 && length <= 20) {
      totalSpacers = 3;
    } else {
      totalSpacers = leading + trailing + Math.floor(length / spacing) - 2;
      if (totalSpacers < 2) {
        totalSpacers = 2;
      }
    }
    return totalSpacers;
  };

  const tapeDetails = {
    A19: { length: 0.3, width: 3 },
    A36: { length: 0.3, width: 3 },
    A50: { length: 0.3, width: 3 },
    B19: { length: 0.3, width: 3 },
    B36: { length: 0.3, width: 3 },
    B50: { length: 0.3, width: 3 },
    C15: { length: 0.3, width: 2 },
    D15: { length: 0.3, width: 2 },
    I15: { length: 0.3, width: 2 },
    S20: { length: 0.1, width: 3 },
    T20: { length: 0.1, width: 3 },
    F25: { length: 0.7, width: 5 },
    F41: { length: 0.7, width: 5 },
    F60: { length: 0.7, width: 5 },
    F75: { length: 0.7, width: 5 },
    G25: { length: 0.3, width: 5 },
    G41: { length: 0.3, width: 5 },
    G60: { length: 0.3, width: 5 },
    M18: { length: 1, width: 7 },
    M25: { length: 1, width: 7 },
    M36: { length: 1, width: 7 },
    M41: { length: 1, width: 7 },
    M50: { length: 1, width: 7 },
    M75: { length: 1, width: 7 },
    M90: { length: 1, width: 7 },
    N18: { length: 0.5, width: 7 },
    N25: { length: 0.5, width: 7 },
    N36: { length: 0.5, width: 7 },
    N50: { length: 0.5, width: 7 },
    N75: { length: 0.5, width: 7 },
    N90: { length: 0.5, width: 7 },
    P110: { length: 1, width: 7 },
    P120: { length: 1, width: 7 },
    Q110: { length: 0.5, width: 7 },
    Q120: { length: 0.5, width: 7 },
    L100: { length: 1, width: 8 },
    L125: { length: 1, width: 8 },
    L150: { length: 1, width: 8 },
    L175: { length: 1, width: 8 },
    L200: { length: 1, width: 8 },
    E25: { length: 1, width: 8 },
    E41: { length: 1, width: 8 },
    E60: { length: 1, width: 8 },
    E75: { length: 1, width: 8 },
    E90: { length: 1, width: 8 },
    E110: { length: 1, width: 8 },
    E130: { length: 1, width: 8 },
    H25: { length: 0.5, width: 8 },
    H41: { length: 0.5, width: 8 },
    H60: { length: 0.5, width: 8 },
    H75: { length: 0.5, width: 8 },
    H90: { length: 0.5, width: 8 },
    H110: { length: 0.5, width: 8 },
    H130: { length: 0.5, width: 8 },
  };
  
  const { totalRunners, averageWidth } = calculateRunnersAndWidth(elementsConfig);

  
  const findMaxWidth = (elementsConfig, tapeDetails) => {
    let maxWidth = 0;
    
    for (const element in elementsConfig) {
      if(tapeDetails[element]?.width > maxWidth) {
        maxWidth = tapeDetails[element].width;
      }
    }
  
    return maxWidth;
  };

  const generateSpacerConfiguration = (elementsConfig) => {
    const configArray = Object.entries(elementsConfig || {}).map(([element, quantity]) => {
      return `${quantity} ${element}${quantity > 1 ? "'s" : ""}`;
    });
    return configArray.join(' and ');
  };

  const calculateTapeNeeded = (elementsConfig, totalSpacers) => {
    if (
      !elementsConfig ||
      Object.keys(elementsConfig).length === 0 ||
      totalSpacers <= 0
    ) {
      return 0;
    }

    let tapeNeeded = 0;

    for (const element in elementsConfig) {
      const quantity = elementsConfig[element];
      const tapeLength = tapeDetails[element]?.length || 0;
      tapeNeeded += quantity * tapeLength;
    }

    return tapeNeeded * totalSpacers;
  };


  const renderProjectSummaryTable = () => {
    const elementTypes = Object.keys(elementsConfig || {});

    // console.log("elementsConfig in fun", elementsConfig);
    const hasLengthValue = lengthOfPipe.some(
      (length) => parseFloat(length) > 0
    );

    if (!hasLengthValue || !numBores || isNaN(numBores)) {
      // console.log("no length value", numBores);
      return null;
    }

    const boreArray = [...Array(Number(numBores))];
    // console.log("boreArray in map", numBores);

    const totalData = boreArray.reduce(
      (acc, _, i) => {
        const length = parseFloat(lengthOfPipe[i]);
        const leadingSpacers = calculateLeadingSpacers(length);
        const trailingSpacers = calculateTrailingSpacers(length);
        const totalSpacers = calculateTotalSpacers(
          length,
          leadingSpacers,
          trailingSpacers
        );

        acc.length += length;
        acc.leadingSpacers += leadingSpacers;
        acc.trailingSpacers += trailingSpacers;
        acc.totalSpacers += totalSpacers;

        elementTypes.forEach(
          (elementType) =>
            (acc[elementType] += elementsConfig[elementType] * totalSpacers)
        );
        return acc;
      },
      {
        length: 0,
        leadingSpacers: 0,
        trailingSpacers: 0,
        totalSpacers: 0,
        ...elementTypes.reduce((acc, elementType) => {
          acc[elementType] = 0;
          return acc;
        }, {}),
      }
    );

    const maxWidth = findMaxWidth(elementsConfig, tapeDetails);


    return (
      <>
        {lengthOfPipe.some((length) => parseFloat(length) > 400) && (
          <p style={{ color: "red" }}>
            Check with a Public Works Representative for Bores over 400ft
          </p>
        )}
        <table
          style={{
            marginBottom: "30px",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  backgroundColor: "#f2f2f2",
                }}
              >
                Bore
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  backgroundColor: "#f2f2f2",
                }}
              >
                Length
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  backgroundColor: "#f2f2f2",
                }}
              >
                Leading Spacers
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  backgroundColor: "#f2f2f2",
                }}
              >
                Trailing Spacers
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  backgroundColor: "#f2f2f2",
                }}
              >
                Total Spacers
              </th>
              {elementTypes.map((elementType, index) => (
                <th
                  key={index}
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  Number of {elementType} Elements
                </th>
              ))}
            <th
  style={{
    border: "1px solid black",
    padding: "8px",
    textAlign: "left",
    whiteSpace: "nowrap",
    backgroundColor: "#f2f2f2",
  }}
>
  Length of {maxWidth}" Tape
</th>
</tr>
          </thead>

          <tbody>
            {boreArray.map((_, i) => {
              const length = parseFloat(lengthOfPipe[i]);
              const leadingSpacers = calculateLeadingSpacers(length);
              const trailingSpacers = calculateTrailingSpacers(length);
              const totalSpacers = calculateTotalSpacers(
                length,
                leadingSpacers,
                trailingSpacers
              );
              return (
                <tr key={i}>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Bore {i + 1}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {length} ft
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {leadingSpacers}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {trailingSpacers}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {Math.ceil(totalSpacers)}
                  </td>
                  {elementTypes.map((elementType, index) => (
                    <td
                      key={index}
                      style={{
                        backgroundColor: "rgb(76, 175, 80)",
                        color: "white",
                        border: "1px solid black",
                      }}
                    >
                      {elementsConfig[elementType] * totalSpacers}
                    </td>
                  ))}
                  <td
    style={{
      border: "1px solid black",
      padding: "8px",
      textAlign: "left",
      whiteSpace: "nowrap",
    }}
  >
{Math.ceil(calculateTapeNeeded(elementsConfig, totalSpacers))} ft

  </td>
    </tr>
              );
            })}
           {numBores > 1 && (
  <tr style={{ fontWeight: "bold" }}>
    <td style={{
      border: "1px solid black",
      padding: "8px",
      textAlign: "left",
      whiteSpace: "nowrap",
    }}>TOTAL</td>
    <td style={{
      border: "1px solid black",
      padding: "8px",
      textAlign: "left",
      whiteSpace: "nowrap",
    }}>{totalData.length} ft</td>
    <td style={{
      border: "1px solid black",
      padding: "8px",
      textAlign: "left",
      whiteSpace: "nowrap",
    }}>{totalData.leadingSpacers}</td>
    <td style={{
      border: "1px solid black",
      padding: "8px",
      textAlign: "left",
      whiteSpace: "nowrap",
    }}>{totalData.trailingSpacers}</td>
    <td style={{
      border: "1px solid black",
      padding: "8px",
      textAlign: "left",
      whiteSpace: "nowrap",
    }}>{totalData.totalSpacers}</td>

    {elementTypes.map((elementType, index) => (
      <td
        key={index}
        style={{
          backgroundColor: "green",
          color: "white",
          border: "1px solid black",
          padding: "8px",
          textAlign: "left",
          whiteSpace: "nowrap",
        }}
      >
        {totalData[elementType]}
      </td>
    ))}
    <td
      style={{
        border: "1px solid black",
        padding: "8px",
        textAlign: "left",
        whiteSpace: "nowrap",
      }}
    >
      {Math.ceil(calculateTapeNeeded(elementsConfig, totalData.totalSpacers))} ft
</td>
  </tr>
)}

          </tbody>
        </table>
      </>
    );
  };


  function calculateMessage() {
    const importantStyle = {
      color: "red",
    };

    const restOfMessageStyle = {
      color: "red",
    };

    return (
      <div>
        <span style={restOfMessageStyle}>
        {!selectedCarrierInfo || !totalGap || totalGap <= 0
          ? null
          : (lengthOfPipe.some(length => Number(length) > 100) && (Number(calculatedBellClearance).toFixed(2) < 0.6 || Number(totalGap).toFixed(2) < 0.6)) || 
            (Number(calculatedBellClearance).toFixed(2) < 0.3 || Number(totalGap).toFixed(2) < 0.3)
          ? `Please note, this application is a tight fit. The spacers selected provide a bell clearance of ${Number(calculatedBellClearance).toFixed(2)}" and a gap of ${Number(totalGap).toFixed(2)}". If you are unsure, please consult a Public Works Representative for assistance.`
          : (lengthOfPipe.some(length => Number(length) > 100) && Number(calculatedBellClearance).toFixed(2) < 0.6)
          ? `Please note, assuming the bell of the carrier pipe is ${selectedCarrierInfo["Bell OD"]}", these spacers only clear the bell by ${Number(calculatedBellClearance).toFixed(2)}". If you are unsure, please consult a Public Works Representative for assistance.`
          : (lengthOfPipe.some(length => Number(length) > 100) && totalGap > 0.01 && totalGap < 0.6)
          ? `Please note, these spacers only provide a ${Number(totalGap).toFixed(2)}" gap for your application. If you are unsure, please consult a Public Works Representative for assistance.`
          : null}
        </span>
      </div>
    );    
  }

  const message = calculateMessage();
  useEffect(() => {
    if (
      carrierType &&
      carrierSize &&
      carrierData[carrierType] &&
      carrierData[carrierType][carrierSize] &&
      !carrierThickness  // check if carrierThickness is not already set
    ) {
      setCarrierThickness(carrierData[carrierType][carrierSize][0]);
    }
  }, [carrierType, carrierSize, elementsConfig]);

  useEffect(() => {
    if (
      casingType &&
      casingSize &&
      carrierData[casingType] &&
      carrierData[casingType][casingSize] &&
      !casingThickness  // check if casingThickness is not already set
    ) {
      setCasingThickness(carrierData[casingType][casingSize][0]);
    }
  }, [casingType, casingSize, elementsConfig]);


  useEffect(() => {
    if (
      carrierType &&
      carrierSize &&
      carrierData[carrierType] &&
      carrierData[carrierType][carrierSize]
    ) {
      setCarrierThickness(carrierData[carrierType][carrierSize][0]);
    }
  }, [
    carrierType,
    carrierSize,
    customCarrierOD,
    customBellOD,
    hasBell,
    lengthOfPipe,
  ]);

  useEffect(() => {
    if (
      casingType &&
      casingSize &&
      carrierData[casingType] &&
      carrierData[casingType][casingSize]
    ) {
      setCasingThickness(carrierData[casingType][casingSize][0]);
    }
  }, [casingType, casingSize, customCasingOD]);

  if (displayOnlySummary) {
    return (
      <div>
        {renderPipeTypes()}
        {renderProjectSummaryTable()}
      </div>
    );
  }

  return (
    <div className="container">
      <div
        style={{
          display: "flex",
        }}
      >
        <input
          type="checkbox"
          checked={showAdvancedOptions}
          onChange={(e) => setShowAdvancedOptions(e.target.checked)}
          style={{
            marginRight: "10px",
            width: "20px",
            height: "20px",
          }}
        />
        <label>Advanced Options Calculator</label>
      </div>
      <div>
        <div>
          <div className="section" style={{ backgroundColor: "#DEECFE" }}>
            <h2>Application Information</h2>
          </div>
          <label>
            Number of Bores with the same carrier and casing pipe on this
            project:
            <input
              type="number"
              min="1"
              max="40"
              value={numBores}
              onChange={(e) => setNumBores(Math.min(40, e.target.value))}
            />
          </label>

          {[...Array(Number(numBores))].map((_, i) => (
            <div key={i}>
              <label>
                Bore {i + 1} Length (ft):
                <input
                  type="number"
                  value={lengthOfPipe[i] || ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue >= 0) {
                      const newLengthOfPipe = [...lengthOfPipe];
                      newLengthOfPipe[i] = inputValue;
                      setLengthOfPipe(newLengthOfPipe);
                    } else {
                      alert("please enter positive number");
                    }
                  }}
                />
              </label>
            </div>
          ))}
        </div>
      </div>
      <div>
        <label>
          Spacing (ft):
          <input
            type="number"
            min="0"
            value={spacing}
            onChange={(e) => setSpacing(e.target.value)}
          />
        </label>
      </div>
      <div>
        <CarrierAndCasingDropdowns
          carrierType={carrierType}
          setCarrierType={setCarrierType}
          carrierSize={carrierSize}
          setCarrierSize={setCarrierSize}
          carrierThickness={carrierThickness}
          setCarrierThickness={setCarrierThickness}
          carrierData={carrierData}
          contentSG={contentSG}
          setContentSG={setContentSG}
          casingType={casingType}
          setCasingType={setCasingType}
          casingSize={casingSize}
          setCasingSize={setCasingSize}
          casingThickness={casingThickness}
          setCasingThickness={setCasingThickness}
          casingData={casingData}
          handleSelectChange={handleSelectChange}
          renderSizeOptions={renderSizeOptions}
          renderThicknessOptions={renderThicknessOptions}
          showAdvancedOptions={showAdvancedOptions}
        />
      </div>

      {showAdvancedOptions && (
        <>
          {/* <style>{tableStyle}</style> */}
          <style>{arrowStyle}</style>
          <div className="section" style={{ backgroundColor: "#DEECFE" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={() =>
                setShowSelectedInformation(!showSelectedInformation)
              }
            >
              <h2 style={{ display: "inline" }}>Pipe Details</h2>
              <i
                className={`arrow ${
                  showSelectedInformation ? "arrow-down" : "arrow-right"
                }`}
              ></i>
            </div>
          </div>
          {showSelectedInformation && (
            <div>
              <table>
                <thead>
                  <tr>
                    <th colSpan="2">Carrier Information</th>
                    <th colSpan="2">Casing Information</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Carrier OD (in):</td>
                    <td>
                      <input
                        type="number"
                        value={
                          customCarrierOD !== null
                            ? customCarrierOD
                            : selectedCarrierInfo &&
                              selectedCarrierInfo["Pipe OD"]
                        }
                        onChange={handleCarrierODChange}
                      />
                    </td>
                    <td>Casing OD (in):</td>
                    <td>
                      <input
                        type="number"
                        value={
                          customCasingOD !== null
                            ? customCasingOD
                            : selectedCasingInfo &&
                              selectedCasingInfo["Pipe OD"]
                        }
                        onChange={(e) => {
                          handleCasingODChange(e);
                          setCasingID(
                            calculateCasingID(e.target.value, customCasingWT)
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Bell OD (in):
                      <input
                        type="checkbox"
                        checked={hasBell}
                        onChange={handleBellCheckboxChange}
                        style={{ marginLeft: "10px" }}
                      />
                    </td>
                    <td>
                      {hasBell ? (
                        <input
                          type="number"
                          value={
                            customBellOD !== null
                              ? customBellOD
                              : selectedCarrierInfo
                              ? selectedCarrierInfo["Bell OD"]
                              : "" // if no carrier is selected, then no bell OD
                          }
                          onChange={handleBellODChange}
                        />
                      ) : (
                        "-n/a-"
                      )}
                    </td>

                    <td>Casing Wall Thickness (in):</td>
                    <td>
                      <input
                        type="number"
                        value={
                          customCasingWT !== null
                            ? customCasingWT
                            : selectedCasingInfo &&
                              selectedCasingInfo["Pipe Wall Thickness"]
                        }
                        onChange={(e) => {
                          handleCasingWTChange(e);
                          setCasingID(
                            calculateCasingID(customCasingOD, e.target.value)
                          );
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Pipe Weight Full of Content (lb/ft):</td>
                    <td>
                      <input
                        type="number"
                        value={
                          customWeight !== null
                            ? customWeight
                            : weightOfCarrierPipeFull.toFixed(2)
                        }
                        onChange={handleweightOfCarrierPipeFullChange}
                      />
                    </td>
                    <td>Casing ID (in):</td>
                    <td>
                      {calculateCasingID(
                        customCasingOD !== null
                          ? customCasingOD
                          : selectedCasingInfo && selectedCasingInfo["Pipe OD"],
                        customCasingWT !== null
                          ? customCasingWT
                          : selectedCasingInfo &&
                              selectedCasingInfo["Pipe Wall Thickness"]
                      )}
                    </td>
                  </tr>
                  <tr></tr>
                  <tr></tr>
                </tbody>
              </table>
            </div>
          )}

          <BellODErrorMessage />
        </>
      )}

      {showAdvancedOptions && (
        <div>
          <style>{arrowStyle}</style>
          <div className="section" style={{ backgroundColor: "#DEECFE" }}>
            <div
              className="section"
              onClick={() =>
                setShowSpacerSelectionCriteria(!showSpacerSelectionCriteria)
              }
            >
              <h2 style={{ display: "inline" }}>Spacer Selection Criteria</h2>
              <i
                className={`arrow ${
                  showSpacerSelectionCriteria ? "arrow-down" : "arrow-right"
                }`}
              ></i>
            </div>
          </div>

          {showSpacerSelectionCriteria && (
            <div>
              <div>
                <label>
                  Max. Runner Height:
                  <input
                    type="number"
                    value={Number(maxRunnerHeight).toFixed(2)}
                    readOnly
                  />
                </label>
              </div>
              <div>
                <label>
                  Min. Runner Height:
                  <input
                    type="number"
                    value={Number(minRunnerHeight).toFixed(2)}
                    readOnly
                  />
                </label>
              </div>
              <div>
                <label>
                  Weight of Carrier Pipe (Full):
                  <input
                    type="number"
                    value={Number(weightOfCarrierPipeFull).toFixed(2)}
                    readOnly
                  />
                  <span> lb/ft</span>
                </label>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="section" style={{ backgroundColor: "#DEECFE" }}>
        <h2>Spacer Selection</h2>
      </div>
      <div>
        {message && (
          <p style={{ color: "red", textAlign: "center", fontWeight: 700 }}>
            {message}
          </p>
        )}
        {getErrorMessage() && (
          <p style={{ color: "red" }}>{getErrorMessage()}</p>
        )}
      </div>
      <div>
        <label>
          Select Product:
          <select value={selectedProduct || ""} onChange={handleProductChange}>
            <option value="">Select Product</option>
            {filteredProducts.map((product) => (
              <option key={product.id} value={product.id}>
                {product.name}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div>
        <label>
          Runner Height (in):
          <input
            type="number"
            value={
              getSelectedProductInfo()
                ? getSelectedProductInfo().fixedRunnerHeight
                : ""
            }
            readOnly
          />
        </label>
      </div>
      <div>
        <div className="section" style={{ backgroundColor: "#DEECFE" }}>
          <h2>Application Details</h2>
        </div>
        {showAdvancedOptions && (
          <label>
            RACI Ring OD (in):
            <input
              type="number"
              value={
                typeof raciRingOD === "number" ? raciRingOD.toFixed(2) : "-"
              }
              readOnly
            />
          </label>
        )}
        <label>
          Bell Clearance (in):
          <input
            type="text"
            value={Number(calculatedBellClearance).toFixed(2)}
            readOnly
            style={{
              backgroundColor: getBackgroundColor(calculatedBellClearance),
              color:
                calculatedBellClearance < 0.6
                  ? "white"
                  : calculatedBellClearance >= 0.6 &&
                    calculatedBellClearance <= 1
                  ? "black"
                  : "black",

              transition: "background-color 0.5s ease",
            }}
          />
        </label>
        <label>
          Total Gap (in):
          <input
            type="number"
            value={totalGap > 0 ? totalGap.toFixed(2) : "-"}
            readOnly
            style={{
              backgroundColor:
                totalGap > 0.01 && totalGap < 0.6 ? "red" : "white",
              color:
                totalGap < 0.6
                  ? "white"
                  : totalGap >= 0.6 && totalGap <= 1
                  ? "black"
                  : "black",
            }}
          />
        </label>
      </div>
      <div>
        <div className="section" style={{ backgroundColor: "#DEECFE" }}>
          <h2>RACI Spacer Configuration</h2>
        </div>
        <table>
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  backgroundColor: "#f2f2f2",
                }}
              >
                Element Type
              </th>
              <th
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  backgroundColor: "#f2f2f2",
                }}
              >
                Quantity
              </th>
            </tr>
          </thead>
          <tbody>
            {(elementsConfig ? Object.entries(elementsConfig) : []).map(
              ([element, quantity]) => (
                <tr key={element}>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {element}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "8px",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {quantity}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      {/* ... */}
      <div>
        {/* ...existing code... */}
        <div className="section" style={{ backgroundColor: "#DEECFE" }}>
          <h2>Project Summary</h2>
          {/* main page bottom table  */}
        </div>
        {renderProjectSummaryTable()}
      
      </div>
      
      <div>
            
        </div>
        <DrawingApp
  casingOD={customCasingOD !== null ? customCasingOD : (selectedCasingInfo ? selectedCasingInfo["Pipe OD"] : 0)}
  casingID={casingID || 0} // Ensure casingID is updated correctly in your state
  carrierOD={customCarrierOD !== null ? customCarrierOD : (selectedCarrierInfo ? selectedCarrierInfo["Pipe OD"] : 0)}
  bellOD={hasBell ? (customBellOD !== null ? customBellOD : (selectedCarrierInfo ? selectedCarrierInfo["Bell OD"] : 0)) : 0}
  spacerOD={raciRingOD || 0}
  gap={totalGap || 0}
  customCasingOD={customCasingOD}
  customBellOD={customBellOD}
  selectedCasingInfo={selectedCasingInfo}
  customCarrierOD={customCarrierOD}
  selectedCarrierInfo={selectedCarrierInfo}
  hasBell={hasBell}
  totalGap={totalGap}
  casingWT={(customCasingWT || customCasingWT === 0) ? customCasingWT : (selectedCasingInfo ? selectedCasingInfo["Pipe Wall Thickness"] : 0)}
  weightOfCarrierPipeFull={(customWeight || customWeight === 0) ? customWeight : weightOfCarrierPipeFull  } // Assuming weightOfCarrierPipeFull is calculated
  spacerConfiguration={generateSpacerConfiguration(elementsConfig)}
  runnerDetails={{ totalRunners, averageWidth }}
  bellClearance={calculatedBellClearance}
  maxWeight={selectedProductInfo ? selectedProductInfo.maxWeight : null}
/>
    </div>
    
  );
};

export default RACISizingCalculator;
