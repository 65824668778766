import React, { useState } from "react";
import RACISizingCalculator from "./RACISizingCalculator";
import logo from "./assets/logo.jpg";
import ReactToPrint from "react-to-print";
import axios from "axios";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CloseOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const RACISizingCalculatorTabs = () => {
  const initialTabs = Array.from({ length: 10 }, (_, i) => ({
    id: i + 1,
    label: `Application ${i + 1}`,
  }));

  const componentRef = React.useRef();
  const [allNumBores, setAllNumBores] = useState(Array(10).fill(1));
  initialTabs.push({ id: 11, label: "Project Summary" });
  const [tabs] = useState(initialTabs);
  const [activeTab, setActiveTab] = useState(1);
  const [allElementsConfigs, setAllElementsConfigs] = useState(
    Array(10).fill({})
  );

  const [allCarrierPipes, setAllCarrierPipes] = useState(Array(10).fill(""));
  const [allCasingPipes, setAllCasingPipes] = useState(Array(10).fill(""));
  const [allLengths, setAllLengths] = useState(Array(10).fill([])); // Initialize with arrays
  const [allApplicationData, setAllApplicationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userNotes, setUserNotes] = useState();

  const handleOnChange = (event) => {
    setUserDetails({
      ...userDetails,
      [event.target.name]: event.target.value,
    });
  };

  const styles = {
    blueText: {
      color: "blue",
      marginRight: 0, // Remove the space between the elements
    },
    carrierPipe: {
      display: "inline",
      color: "blue",
      marginRight: "5px",
    },
    casingPipe: {
      display: "inline",
      color: "blue",
      marginLeft: "5px",
    },
  };

  function extractTableValues(componentRef) {
    const rows = componentRef.getElementsByTagName("tr");
    const headers = rows[0].getElementsByTagName("th");
    const applicatonData = [];

    for (let i = 1; i < rows.length; i++) {
      const rowData = {};
      const cells = rows[i].getElementsByTagName("td");

      for (let j = 0; j < headers.length; j++) {
        const headerText = headers[j].textContent.trim();
        const cellValue = cells[j]?.textContent?.trim();
        rowData[headerText] = cellValue;
      }

      applicatonData.push(rowData);
      setAllApplicationData(applicatonData);
    }
    return applicatonData;
  }

  const handleRequestQuote = () => {
    extractTableValues(componentRef.current);
    // setShowEmailButton(true);
    setDialogOpen(true);
  };

  const sendEmailData = async (componentRef) => {
    if (userDetails === "" || userDetails === undefined) {
      alert("Please enter the required fields.");
      return;
    }
    const notesData = userNotes;
    setLoading(true);
    try {
      const allApplicationData = componentRef.allApplicationData;
      const numApplications = allApplicationData?.length;

      // Add table style to componentRef

      for (let i = 0; i < numApplications; i++) {
        const applicationData = allApplicationData[i];
        const table = document.createElement("table");

        const header = document.createElement("h3");
        header.textContent = `Application Number: ${i + 1}`;
        componentRef.appendChild(header);

        const notesDiv = document.createElement("div");
        const notesHeader = document.createElement("h3");
        notesHeader.textContent = "Notes";
        const notesContent = document.createElement("p");
        notesContent.textContent = notesData;
        notesDiv.appendChild(notesHeader);
        notesDiv.appendChild(notesContent);
        componentRef.appendChild(notesDiv);

        for (const [key, value] of Object.entries(applicationData)) {
          const row = document.createElement("tr");
          const keyColumn = document.createElement("td");
          const valueColumn = document.createElement("td");
          keyColumn.textContent = key;
          valueColumn.textContent = value;
          row.appendChild(keyColumn);
          row.appendChild(valueColumn);
          table.appendChild(row);
        }

        componentRef.appendChild(table);
        // Add a line break after each application
        componentRef.appendChild(document.createElement("br"));
      }

      const htmlString = componentRef.outerHTML;

      const response = await axios.post(
        "smtp.pwp-co.com",
        {
          to: "sales@pwp-co.com",
          subject: `Applications Summary of ${userDetails.projectName}`,
          cc: userDetails?.email,
          message: `${notesData}\n${htmlString}`,
          // form: it should be @gmail.com
        }
      );

      if (response) {
        setLoading(false);
        setUserDetails("");
        setUserNotes("");
        toast.success("Email send Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setDialogOpen(false);
      } else {
        setLoading(true);
        toast.error("Something went wrong!", {
          autoClose: 2000,
          position: "top-right",
          closeOnClick: true,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          draggable: true,
        });
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
      console.log("response", response);
    } catch (error) {
      setLoading(true);
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      console.error(error);
    }
  };

  return (
    <>
      <div className="top-strip"></div>
      <div className="logo-header">
        <a href="http://www.publicworksproducts.com" rel="noopener noreferrer">
          <img src={logo} alt="Company Logo" className="logo" />
        </a>
      </div>
      <header className="app-header"></header>
      <div className="container">
        <div className="logOutButtonContainer">
          <div>
            <h1 className="text-center my-4 title">RACI Sizing Calculator</h1>
          </div>
        </div>

        <div className="nav nav-pills" role="tablist">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`nav-link navButton custom-tab${
                activeTab === tab.id ? " active" : ""
              }`}
              style={
                tab.id === 11
                  ? {
                      background: "#4caf50",
                      fontWeight: "bold",
                      color: "white",
                    }
                  : null
              }
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>

        <div>
          {tabs.map((tab) => (
            <div
              key={tab.id}
              style={{ display: activeTab === tab.id ? "block" : "none" }}
            >
              {tab.id !== 11 ? (
                <RACISizingCalculator
                  lengthOfPipe={allLengths[tab.id - 1]} // Pass down the length state
                  activeTab={activeTab}
                  setLengthOfPipe={(newLengths) => {
                    // Update the allLengths state when the length changes in the child component
                    setAllLengths((prevAllLengths) => {
                      const updatedLengths = [...prevAllLengths];
                      updatedLengths[tab.id - 1] = newLengths;

                      return updatedLengths;
                    });
                  }}
                  elementsConfig={allElementsConfigs[tab.id - 1]}
                  setElementsConfig={(newElementsConfig) => {
                    setAllElementsConfigs((prevAllElementsConfigs) => {
                      const updatedElementsConfigs = [
                        ...prevAllElementsConfigs,
                      ];
                      updatedElementsConfigs[tab.id - 1] = newElementsConfig;
                      return updatedElementsConfigs;
                    });
                  }}
                  numBores={allNumBores[tab.id - 1]}
                  setNumBores={(newNumBores) => {
                    setAllNumBores((prevAllNumBores) => {
                      const updatedNumBores = [...prevAllNumBores];
                      updatedNumBores[tab.id - 1] = newNumBores;
                      return updatedNumBores;
                    });
                  }}
                  CarrierPipe={allCarrierPipes[tab.id - 1]}
                  setCarrierPipe={(newCarrierPipe) => {
                    setAllCarrierPipes((prevAllCarrierPipes) => {
                      const updatedCarrierPipes = [...prevAllCarrierPipes];
                      updatedCarrierPipes[tab.id - 1] = newCarrierPipe;
                      return updatedCarrierPipes;
                    });
                  }}
                  CasingPipe={allCasingPipes[tab.id - 1]}
                  setCasingPipe={(newCasingPipe) => {
                    setAllCasingPipes((prevAllCasingPipes) => {
                      const updatedCasingPipes = [...prevAllCasingPipes];
                      updatedCasingPipes[tab.id - 1] = newCasingPipe;
                      return updatedCasingPipes;
                    });
                  }}
                />
              ) : (
                <div>
                  <div className="headerButtonWrapper">
                    <div
                      onClick={() => {
                        extractTableValues(componentRef.current);
                      }}
                    >
                      <ReactToPrint
                        trigger={() => {
                          return (
                            <button
                              disabled={!componentRef.current?.innerHTML}
                              className={`custom-button ${
                                !componentRef.current?.innerHTML
                                  ? "disabled-button"
                                  : ""
                              }`}
                              type="button"
                              onClick={() =>
                                handleRequestQuote(componentRef.current)
                              }
                            >
                              Generate Pdf
                            </button>
                          );
                        }}
                        content={() => componentRef.current}
                        documentTitle="RACI Quote Request"
                        pageStyle={styles.pageStyle}
                      />
                    </div>

                    <div>
                      {/* <button
                        disabled={!componentRef.current?.innerHTML}
                        className={`custom-button ${
                          !componentRef.current?.innerHTML
                            ? "disabled-button"
                            : ""
                        }`}
                        type="button"
                        onClick={() => handleRequestQuote(componentRef.current)}
                      >
                        Request Quote
                      </button> */}
                      
                      {/* )} */}
                    </div>
                  </div>

                  {/* <h2>All Applications</h2> */}
                  {tab.id === 11 && (
  <div>
    <h2
      style={{
        textAlign: "center",
        margin: "20px 0",
      }}
    >
      Project Summary
    </h2>
    {/* Instruction Text */}
    <p
      style={{
        textAlign: "center",
        margin: "20px 0",
        fontSize: "18px",
        color: "#4caf50", // You can adjust the color to fit your design
      }}
    >
      Please email your application details to <a href="mailto:sales@pwp-co.com">sales@pwp-co.com</a> for a quote.
    </p>
    {/* The rest of your Project Summary code */}
  </div>
)}

                  {/* <PDFInfo /> */}
                  <div
                    ref={componentRef}
                    style={{
                      padding: "30px",
                    }}
                  >
                    {allLengths.some((lengthArray) =>
                      lengthArray.some((length) => parseFloat(length) > 0)
                    ) &&
                      initialTabs.slice(0, 10).map((applicationTab) => {
                        const hasLengthValue = allLengths[
                          applicationTab.id - 1
                        ].some((length) => parseFloat(length) > 0);
                        const elementsConfig =
                          allElementsConfigs[applicationTab.id - 1];
                        const numBores =
                          allNumBores[applicationTab.id - 1] || 1;
                        // Retrieve the data for the active tab

                        return (
                          hasLengthValue && (
                            <div key={applicationTab.id}>
                              <h3
                                style={{
                                  backgroundColor: "#4caf50",
                                  borderRadius: "10px",
                                  padding: "10px",
                                  color: "white",
                                  width: "fit-content",
                                  marginBottom: "18px",
                                }}
                              >
                                {applicationTab.label}
                              </h3>
                              <div>
                                <p
                                  style={{
                                    fontWeight: 700,
                                    color: "#2A7DE1",
                                    paddingRight: 2,
                                  }}
                                >
                                  Carrier Pipe: {""}
                                  {""} {""} {""} {""} {""}
                                  <span
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    {allCarrierPipes[applicationTab.id - 1]}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p
                                  style={{
                                    fontWeight: 700,
                                    color: "#2A7DE1",
                                  }}
                                >
                                  Casing Pipe: {""} {""} {""} {""} {""} {""}
                                  <span
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    {allCasingPipes[applicationTab.id - 1]}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <RACISizingCalculator
                                  displayOnlySummary={true}
                                  numBores={numBores} // Pass the actual numBores value
                                  lengthOfPipe={
                                    allLengths[applicationTab.id - 1]
                                  } // Pass the entire length array
                                  elementsConfig={elementsConfig}
                                />
                              </div>
                            </div>
                          )
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          ))}
          <Dialog open={dialogOpen}>
            <DialogTitle
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Quote Request Form
              <IconButton
                aria-label="close"
                onClick={() => setDialogOpen(false)}
                sx={{
                  backgroundColor: "#2A7DE1",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#2A7DE1",
                    boxShadow: 3,
                  },
                }}
              >
                <CloseOutlined
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the following information to request a quote.
              </DialogContentText>
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                label="Project Reference"
                fullWidth
                variant="outlined"
                name="projectName"
                value={userDetails.projectName || ""}
                onChange={(event) => handleOnChange(event)}
              />
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                variant="outlined"
                name="email"
                value={userDetails.email || ""}
                onChange={(event) => handleOnChange(event)}
              />
              <TextField
                autoFocus
                multiline
                rows={3}
                margin="dense"
                id="name"
                label="Notes or Comments (Optional)"
                fullWidth
                variant="outlined"
                name="notes"
                value={userNotes || ""}
                onChange={(event) => setUserNotes(event.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => sendEmailData(componentRef.current)}
                sx={{
                  backgroundColor: "rgb(76, 175, 80)",
                  color: "white",
                  width: "150px",
                  padding: "12px",
                  borderRadius: "10px",
                  mr: 2,
                  "&:hover": {
                    backgroundColor: "rgb(76, 175, 80)",
                    color: "white",
                    boxShadow: 10,
                  },
                }}
              >
                {loading ? "Loading..." : "Send"}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default RACISizingCalculatorTabs;
