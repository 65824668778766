import React, { useMemo } from 'react';

const DrawingApp = ({ 
  casingOD, casingID, carrierOD, bellOD, spacerOD, gap, totalRunners, averageWidth, runnerDetails, maxWeight, selectedProductInfo,
  customCasingOD, selectedCasingInfo, customCarrierOD, selectedCarrierInfo, hasBell, customBellOD, spacerConfiguration, totalGap, carrierThickness, customWeight, weightOfCarrierPipeFull, customCasingWT, bellClearance, calculatedBellClearance
}) => {
  // Function to calculate the dynamic scaling factor
  const calculateScale = (maxDiameter) => {
    const maxSvgDiameter = 500; // Maximum diameter in SVG units that we can fit in the canvas
    return maxSvgDiameter / maxDiameter;
  };

  // Use useMemo to only recalculate scale when diameters change
  const scale = useMemo(() => {
    const maxDiameter = Math.max(casingOD, spacerOD);
    return calculateScale(maxDiameter);
  }, [casingOD, spacerOD]);  

  // Calculate radii based on props
  const casingODRadius = (casingOD / 2) * scale;
  const casingIDRadius = (casingID / 2) * scale;
  const carrierODRadius = (carrierOD / 2) * scale;
  const bellODRadius = (bellOD / 2) * scale;
  const spacerODRadius = (spacerOD / 2) * scale;
  
  const centerX = 400;
  const centerY = 700;
  const dimensionStartOffset = 100;
  const casingCenterY = 500 - casingIDRadius; // Casing bottom is at the bottom of the SVG
  const spacerCenterY = 500 - spacerODRadius; // Spacer bottom touches bottom of the casing
  const carrierCenterY = spacerCenterY; // Carrier is centered within the spacer
  const bellCenterY = spacerCenterY; // Bell is centered within the spacer
  const topCasingY = casingCenterY - casingIDRadius; // Top center of the casing ID
  const topSpacerY = spacerCenterY - spacerODRadius;
  const runnerHeight = (spacerOD - carrierOD) / 2;

  
  // Calculate the start and end points for the line
const lineStartY = casingCenterY + casingIDRadius;
const lineEndY = casingCenterY - casingIDRadius;

// Line offset from the casing
const lineOffset = -600; // Adjust as needed
const lineX = centerX + casingODRadius + lineOffset;

// Horizontal line dimensions
const horizontalLineLength = 350; // Adjust as needed
const horizontalLineTopX1 = lineX;
const horizontalLineTopX2 = lineX + horizontalLineLength;
const horizontalLineBottomX1 = lineX;
const horizontalLineBottomX2 = lineX + horizontalLineLength;

// Label position
const labelX = lineX + 10; // Adjust as needed
const labelY = (lineStartY + lineEndY) / 2; // Position the label at the midpoint of the line

const labelBOffset = 15; // Horizontal offset for the label from the gap line
const labelBX = centerX + labelBOffset; // Adjust as needed
const labelBY = (topSpacerY + topCasingY) / 2 + 5; // Position the label at the midpoint of the gap line

// Calculate the start and end points for the new line
const carrierLineStartY = spacerCenterY + carrierODRadius + 2.5; // Bottom of the carrier OD
const carrierLineEndY = spacerCenterY - carrierODRadius - 2.5; // Top of the carrier OD

// Line offset for the new line (similar to the previous line)
const carrierLineOffset = -550; // Adjust as needed
const carrierLineX = centerX + casingODRadius + carrierLineOffset;

// Horizontal line dimensions for the carrier OD lines
const horizontalCarrierLineLength = 300; // Adjust as needed
const horizontalCarrierLineTopX1 = carrierLineX;
const horizontalCarrierLineTopX2 = carrierLineX + horizontalCarrierLineLength;
const horizontalCarrierLineBottomX1 = carrierLineX;
const horizontalCarrierLineBottomX2 = carrierLineX + horizontalCarrierLineLength;

// Label position for the new line
const labelDX = carrierLineX + 10; // Adjust as needed
const labelDY = (carrierLineStartY + carrierLineEndY) / 2; // Position the label at the midpoint of the line

// Existing variables for runner height line
const runnerHeightLineStartY = casingCenterY + casingIDRadius; // Point at casing ID (6 o'clock position)
const runnerHeightLineEndY = carrierCenterY + carrierODRadius + 2.5; // Point at carrier OD (6 o'clock position)
const runnerHeightLineX = centerX + casingODRadius - 550; // Adjust as needed

// Corrected horizontal line dimensions for runner height
// The length should be the distance from the runner height line to the casing ID and carrier OD
const horizontalRunnerHeightLineLengthTop = runnerHeightLineX - (centerX - casingIDRadius);
const horizontalRunnerHeightLineLengthBottom = runnerHeightLineX - (centerX);

// Horizontal line positions
const horizontalRunnerHeightLineTopX1 = runnerHeightLineX;
const horizontalRunnerHeightLineTopX2 = runnerHeightLineX - horizontalRunnerHeightLineLengthTop;
const horizontalRunnerHeightLineBottomX1 = runnerHeightLineX;
const horizontalRunnerHeightLineBottomX2 = runnerHeightLineX - horizontalRunnerHeightLineLengthBottom;

// Label position for runner height line
const labelRunnerHeightX = runnerHeightLineX + 10; // Adjust as needed
const labelRunnerHeightY = (runnerHeightLineStartY + runnerHeightLineEndY) / 2;

// Calculate the start and end points for the Bell Clearance line
const bellClearanceLineStartY = spacerCenterY - spacerODRadius; // Top of the spacer OD
const bellClearanceLineEndY = spacerCenterY - bellODRadius; // Bottom of the Bell OD

// Line offset for the Bell Clearance line (aligned with (d) and (e) lines)
const bellClearanceLineOffset = -550; // Adjust as needed, same as carrierLineOffset
const bellClearanceLineX = centerX + casingODRadius + bellClearanceLineOffset;

// Horizontal line dimensions for the Bell Clearance lines
const horizontalBellClearanceLineLength = 300; // Adjust as needed
const horizontalBellClearanceLineTopX1 = bellClearanceLineX;
const horizontalBellClearanceLineTopX2 = bellClearanceLineX + horizontalBellClearanceLineLength;
const horizontalBellClearanceLineBottomX1 = bellClearanceLineX;
const horizontalBellClearanceLineBottomX2 = bellClearanceLineX + horizontalBellClearanceLineLength;

// Label position for the Bell Clearance line
const labelFX = bellClearanceLineX + 10; // Adjust as needed
const labelFY = (bellClearanceLineStartY + bellClearanceLineEndY) / 2; // Midpoint of the line
<defs>
  <linearGradient id="runnerGradient" x1="0%" y1="0%" x2="100%" y2="0%">
    <stop offset="0%" style={{stopColor: '#0099ff', stopOpacity: 1}} />
    <stop offset="100%" style={{stopColor: '#66ccff', stopOpacity: 1}} />
  </linearGradient>
</defs>
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Form submitted with values:', { casingOD, casingID, carrierOD, bellOD, spacerOD, gap });
  };

  const generateRunners = (numberOfRunners, runnerWidth) => {
    const runners = [];
    const runnerRadius = runnerWidth / 2; // Radius of the runner's rounded end
    const runnerLength = spacerODRadius - carrierODRadius - runnerRadius; // Length adjusted for runner tip
  
    for (let i = 0; i < numberOfRunners; i++) {
      const angle = (2 * Math.PI / numberOfRunners) * i; // Angle for each runner
      const innerX = centerX + carrierODRadius * Math.cos(angle);
      const innerY = carrierCenterY + carrierODRadius * Math.sin(angle);
      const outerX = centerX + (carrierODRadius + runnerLength) * Math.cos(angle);
      const outerY = carrierCenterY + (carrierODRadius + runnerLength) * Math.sin(angle);
  
      // Runner line
      runners.push(
        <line
          key={`runner-line-${i}`}
          x1={innerX}
          y1={innerY}
          x2={outerX}
          y2={outerY}
          stroke="#0000FF" // Color of the runner
          strokeWidth={runnerWidth}
        />
      );
  
      // Rounded end at the outer tip of the runner
      runners.push(
        <circle
          key={`runner-end-${i}`}
          cx={outerX}
          cy={outerY}
          r={runnerRadius}
          fill="#0000FF"
        />
      );
    }
    return runners;
  };


  return (
    <div>
  
      <svg width="1200" height="1200">

      <path
    d={`M ${centerX + casingODRadius} ${casingCenterY} 
        A ${casingODRadius} ${casingODRadius} 0 1 1 ${centerX - casingODRadius} ${casingCenterY} 
        A ${casingODRadius} ${casingODRadius} 0 1 1 ${centerX + casingODRadius} ${casingCenterY} 
        M ${centerX + casingIDRadius} ${casingCenterY}
        A ${casingIDRadius} ${casingIDRadius} 0 1 0 ${centerX - casingIDRadius} ${casingCenterY}
        A ${casingIDRadius} ${casingIDRadius} 0 1 0 ${centerX + casingIDRadius} ${casingCenterY}`}
    fill="#808080"
  />

{bellOD > 0 && (
          <path
            d={`M ${centerX + bellODRadius} ${carrierCenterY} 
                A ${bellODRadius} ${bellODRadius} 0 1 1 ${centerX - bellODRadius} ${carrierCenterY} 
                A ${bellODRadius} ${bellODRadius} 0 1 1 ${centerX + bellODRadius} ${carrierCenterY} 
                M ${centerX + carrierODRadius} ${carrierCenterY}
                A ${carrierODRadius} ${carrierODRadius} 0 1 0 ${centerX - carrierODRadius} ${carrierCenterY}
                A ${carrierODRadius} ${carrierODRadius} 0 1 0 ${centerX + carrierODRadius} ${carrierCenterY}`}
            fill="#EBFFEB" // Very light yellow color
          />
        )}

        {/* SVG circles for CasingOD and CasingID */}
        <circle cx={centerX} cy={casingCenterY} r={casingODRadius} stroke="black" strokeWidth="4" fill="none" />
        <circle cx={centerX} cy={casingCenterY} r={casingIDRadius} stroke="black" strokeWidth="2" fill="none" />
        
        <circle
  cx={centerX}
  cy={carrierCenterY}
  r={carrierODRadius + 5} // Add 2 units to the radius for the blue line
  stroke="#0000FF" // Blue color
  strokeWidth="5" // Thin line
  fill="none" // No fill, just the stroke
/>
        
        {/* Spacer positioned so the bottom touches the bottom of the casing */}
        <circle cx={centerX} cy={spacerCenterY} r={spacerODRadius} stroke="#ADD8E6" strokeWidth="1" fill="none" /> {/* Lighter blue and thinner line */}
        
        <circle cx={centerX} cy={bellCenterY} r={bellODRadius} stroke="#D2B55B" strokeWidth="2" fill="none" />

        {/* Carrier and Bell centered within the spacer */}
        {generateRunners(runnerDetails.totalRunners, runnerDetails.averageWidth)}
        <circle cx={centerX} cy={carrierCenterY} r={carrierODRadius} stroke="green" strokeWidth="5" fill="white" />
        
        {/* Gap as a vertical line at the 12 o'clock position */}
        <line x1={centerX} y1={topSpacerY} x2={centerX} y2={topCasingY} stroke="orange" strokeWidth="2" />
          {/* Spacer runners */}
          
          {/* Vertical Dimensional Line for Casing */}
      <line
        x1={lineX}
        y1={lineStartY}
        x2={lineX}
        y2={lineEndY}
        stroke="black"
        strokeWidth="2"
      />

      {/* Horizontal Line at the Top */}
      <line
        x1={horizontalLineTopX1}
        y1={lineEndY}
        x2={horizontalLineTopX2}
        y2={lineEndY}
        stroke="black"
        strokeWidth="0.3" // Thin line
      />

      {/* Horizontal Line at the Bottom */}
      <line
        x1={horizontalLineBottomX1}
        y1={lineStartY}
        x2={horizontalLineBottomX2}
        y2={lineStartY}
        stroke="black"
        strokeWidth="0.3" // Thin line
      />
      {/* Label for the Dimensional Line */}
      <text
        x={labelX}
        y={labelY}
        fill="black"
        fontSize="12"
        fontWeight="bold"
      >
        (c)
      </text>
      <text
        x={labelBX}
        y={labelBY}
        fill="black"
        fontSize="12"
        fontWeight="bold"
      >
        (g)
      </text>
      <line
        x1={carrierLineX}
        y1={carrierLineStartY}
        x2={carrierLineX}
        y2={carrierLineEndY}
        stroke="black"
        strokeWidth="2"
      />
{/* Horizontal Line at the Top for Carrier OD */}
<line
        x1={horizontalCarrierLineTopX1}
        y1={carrierLineEndY}
        x2={horizontalCarrierLineTopX2}
        y2={carrierLineEndY}
        stroke="black"
        strokeWidth="0.3" // Thin line
      />

      {/* Horizontal Line at the Bottom for Carrier OD */}
      <line
        x1={horizontalCarrierLineBottomX1}
        y1={carrierLineStartY}
        x2={horizontalCarrierLineBottomX2}
        y2={carrierLineStartY}
        stroke="black"
        strokeWidth="0.3" // Thin line
      />
      {/* Label for the New Line */}
      <text
        x={labelDX}
        y={labelDY}
        fill="black"
        fontSize="12"
        fontWeight="bold"
      >
        (d)
      </text>
      {/* Vertical Line for Runner Height */}
      <line
        x1={runnerHeightLineX}
        y1={runnerHeightLineStartY}
        x2={runnerHeightLineX}
        y2={runnerHeightLineEndY}
        stroke="black"
        strokeWidth="2"
      />

      {/* Horizontal Line at the Top for Runner Height */}
      <line
        x1={horizontalRunnerHeightLineTopX1}
        y1={runnerHeightLineStartY}
        x2={horizontalRunnerHeightLineTopX2}
        y2={runnerHeightLineStartY}
        stroke="black"
        strokeWidth="0.3" // Thin line
      />

      {/* Horizontal Line at the Bottom for Runner Height */}
      <line
        x1={horizontalRunnerHeightLineBottomX1}
        y1={runnerHeightLineEndY}
        x2={horizontalRunnerHeightLineBottomX2}
        y2={runnerHeightLineEndY}
        stroke="black"
        strokeWidth="0.3" // Thin line
      />

      {/* Label for the Runner Height Line */}
      <text
        x={labelRunnerHeightX}
        y={labelRunnerHeightY}
        fill="black"
        fontSize="12"
        fontWeight="bold"
      >
        (j)
      </text>

      {hasBell && (
  <>
    {/* Vertical Line for Bell Clearance */}
    <line
      x1={bellClearanceLineX}
      y1={bellClearanceLineStartY}
      x2={bellClearanceLineX}
      y2={bellClearanceLineEndY}
      stroke="black"
      strokeWidth="2"
    />

    {/* Horizontal Line at the Top for Bell Clearance */}
    <line
      x1={horizontalBellClearanceLineTopX1}
      y1={bellClearanceLineStartY}
      x2={horizontalBellClearanceLineTopX2}
      y2={bellClearanceLineStartY}
      stroke="black"
      strokeWidth="0.3"
    />

    {/* Horizontal Line at the Bottom for Bell Clearance */}
    <line
      x1={horizontalBellClearanceLineBottomX1}
      y1={bellClearanceLineEndY}
      x2={horizontalBellClearanceLineBottomX2}
      y2={bellClearanceLineEndY}
      stroke="black"
      strokeWidth="0.3"
    />

    {/* Label for the Bell Clearance Line */}
    <text
      x={labelFX}
      y={labelFY}
      fill="black"
      fontSize="12"
      fontWeight="bold"
    >
      (h)
    </text>
  </>
)}

    {/* Legend Background */}
    <rect x="810" y="10" width="320" height="410" fill="#e6e6e6" stroke="black" strokeWidth="2"/>

    {/* Legend Title */}
    <text x="820" y="40" fontWeight="bold" fontSize="20" fill="#333">Application Details</text>

    {/* Casing Information */}
    <text x="820" y="70" fontSize="16" fill="#4a4a4a" fontWeight="bold">Casing Information:</text>
    <text x="830" y="95" fontSize="14" fontWeight="bold">(a) Outer Diameter: {customCasingOD !== null ? customCasingOD : (selectedCasingInfo ? selectedCasingInfo["Pipe OD"] : 0)}"</text>
    <text x="830" y="120" fontSize="14" fontWeight="bold">(b) Wall Thickness: {(customCasingWT || customCasingWT === 0) ? customCasingWT : (selectedCasingInfo ? selectedCasingInfo["Pipe Wall Thickness"] : 0)}"</text>
    <text x="830" y="145" fontSize="14" fontWeight="bold">(c) Inner Diameter: {casingID || 0}"</text>

    {/* Carrier Information */}
    <text x="820" y="175" fontSize="16" fill="#4a4a4a" fontWeight="bold">Carrier Information:</text>
    <text x="830" y="200" fontSize="14" fontWeight="bold">(d) Outer Diameter: {customCarrierOD !== null ? customCarrierOD : (selectedCarrierInfo ? selectedCarrierInfo["Pipe OD"] : 0)}"</text>
    <text x="830" y="225" fontSize="14" fontWeight="bold">(e) Bell: {hasBell ? (customBellOD !== null ? `${customBellOD}"` : (selectedCarrierInfo ? `${selectedCarrierInfo["Bell OD"]}"` : 'n/a')) : 'n/a'}</text>
    <text x="830" y="250" fontSize="14" fontWeight="bold">(f) Estimated Weight (full): {Number((customWeight || customWeight === 0) ? customWeight : weightOfCarrierPipeFull).toFixed(2)} lbs/ft</text>

    {/* Application Details */}
    <text x="820" y="280" fontSize="16" fill="#4a4a4a" fontWeight="bold">Application Details:</text>
    <text x="830" y="305" fontSize="14" fontWeight="bold">(g) Gap: {Number(totalGap).toFixed(2)}"</text>
    <text x="830" y="330" fontSize="14" fontWeight="bold">(h) Bell Clearance: {hasBell ? `${Number(bellClearance !== null ? bellClearance : calculatedBellClearance).toFixed(2)}"` : 'n/a'}</text>
    <text x="830" y="355" fontSize="14" fontWeight="bold">(i) Spacer Configuration: {spacerConfiguration}</text>
    <text x="830" y="380" fontSize="14" fontWeight="bold">(j) Runner Height: {Number(runnerHeight).toFixed(2)}"</text>
    <text x="830" y="405" fontSize="14" fontWeight="bold">(k) Max Weight Per Spacer: {maxWeight || 'N/A'} lbs</text>
  </svg>
  
</div>

);
};

export default DrawingApp;